const numberFormats = new Map();
export class NumberFormatter {
  /**
   * Format a number.
   * @param {number} value
   * @param {number} decimals
   */
  static format(value, decimals) {
    if (value == null) {
      return '';
    } else {
      let formatter = numberFormats.get(decimals);
      if (!formatter) {
        formatter = this.addFormat(decimals);
      }
      return formatter.format(value);
    }
  }

  static addFormat(decimals) {
    const formatter = new Intl.NumberFormat('da-dk', {
      useGrouping: true,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
    numberFormats.set(decimals, formatter);
    return formatter;
  }
}

const standardDateFormat = new Intl.DateTimeFormat('da-dk', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
});

const humanDateFormat = new Intl.DateTimeFormat('da-dk', {
  day: 'numeric',
  month: 'short'
});

function reverse(str, char) {
  return str.split(char).reverse().join(char);
}

export const date = ms => {
  try {
    return(DateFormatter.format(ms,'yyyy-mm-dd'));
    } catch (err) {
    return '';
  }
};


export class DateFormatter {
  static format(value, format) {
    const stdFormat = standardDateFormat.format(value).replace(/\//g, '-').replace(/\./g,'-');
    switch (format) {
      case 'dd/mm/yyyy':
        return stdFormat.replace(/-/g, '/');
      case 'yyyy/mm/dd':
        return reverse(stdFormat, '-').replace(/\-/g, '/');
      case 'dd-mm-yyyy':
        return stdFormat;
      case 'yyyy-mm-dd':
        return reverse(stdFormat,'-');
      case 'd. mon.':
        return humanDateFormat.format(value);
      case 'mon. d.':
        return reverse(humanDateFormat.format(value), ' ');
    }
  }
}
