
import { LitElement, html } from 'lit-element';
import { createElement, createSVG, toX, toY, formatNumber, formatDate, Chart } from './utils.js';


const Constants = {
  PADDING_X: 140,
  PADDING_Y: 100,
  TICK_SPACING_X: 100,
  TICK_SPACING_Y: 50
}

export default class LineChartElement extends LitElement {

  static get properties() {
    return {
      txtdata: {
        type: String
      },
      txtrect: {
        type: String
      }
    }
  }

  constructor() {
    super();
    this.data = [];
    this.chart = {};
    this.rect = { width: 1800, height: 400 }

    //this.paint = this.paint.bind(this);

    this._onResize = this._onResize.bind(this);
  }

  async attributeChangedCallback(name, _old, value) {
    //   console.log('linechart attrib change ', name)
    //   console.log('linechart attrib change ', value)

    if (name === "txtdata") {
      this.data = JSON.parse(value);      
      this.paint();
    }
    if (name === "txtrect") {
      this.rect = JSON.parse(value);
      this.paint();
    }
    this.requestUpdate();
  }


  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('resize', this._onResize);
    this.layout();
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this._onResize);
    super.disconnectedCallback();
  }

  _onResize(event) {
    // console.log('Line chart resize');
    clearTimeout(this._throttle);
    this._throttle = setTimeout(() => {
      this.layout();
      this.paint();
    }, 300);
  }

  dateFunction(x) {
    return formatDate(x);
  }


  render() {
    //  console.log('linechart render');
    return html`
    <style>
      :host {
        display: block;
        height: 400px;
      }
      
      path {
        stroke-width: 2px;
        stroke: var(--primary-color);
        fill: none;
      }
      
      circle {
        fill: var(--primary-color);
        stroke: #ffffff;
        stroke-width: 2px;
      }
      
      line {
        stroke: #dadada;
      }
      
      .x-axis text {
        text-anchor: middle;
      }
      
      .y-axis text {
        text-anchor: end;
      }
    </style>
    <svg width="100%" height="100%">
   ${this.chart}
    </svg>
    `;
  }
  /*<svg id="root" width="100%" height="100%"></svg> */


  layout() {
    // const { width, height } = this.getBoundingClientRect();
    const height = this.rect.height;
    const width = this.rect.width;
    const { PADDING_X, PADDING_Y } = Constants;
    this.paddedWidth = width - PADDING_X;
    this.paddedHeight = height - PADDING_Y;

    const { TICK_SPACING_X, TICK_SPACING_Y } = Constants;

    this.maxTicks = {
      x: Math.floor(this.paddedWidth / TICK_SPACING_X),
      y: Math.floor(this.paddedHeight / TICK_SPACING_Y)
    }
  }

  paint() {
    this.chart = {}
    if ((this.rect.width === 0) || (this.rect.height === 0)) {
      return;
    }

    if (this.data.length === 0) {
      return;
    }
    this.layout();

    const root = createSVG("svg", { attributes: { height: this.rect.height, width: this.rect.width } });

    const xAxisValues = (this.data || []).map(d => d[0]);
    const yAxisValues = (this.data || []).map(d => d[1]);
    const maxX = Math.max(...xAxisValues);
    const maxY = Math.max(...yAxisValues);
    const minX = Math.min(...xAxisValues);
    const minY = Math.min(...yAxisValues);

    const yAxis = Chart.scales(minY, maxY, this.maxTicks.y);

    // y-axis
    const yAxisGroup = createSVG('g', {
      attributes: {
        class: 'y-axis'
      }
    });
    for (let i = 0; i <= yAxis.ticks; i++) {
      const current = i * yAxis.spacing + yAxis.min;
      const formatted = formatNumber(current);
      const coordinate = toY(
        current,
        yAxis.min,
        yAxis.max,
        this.paddedHeight
      );
      const text = createSVG('text', {
        properties: {
          textContent: formatted
        },
        attributes: {
          x: -8,
          y: 0,
          dy: '.33em'
        }
      });

      const line = createSVG('line', {
        attributes: {
          x1: 0,
          x2: this.paddedWidth,
          y1: 0,
          y2: 0
        }
      });

      const tick = createSVG('g', {
        attributes: {
          transform: `translate(0, ${coordinate})`
        }
      });


      tick.appendChild(text);
      tick.appendChild(line);
      yAxisGroup.appendChild(tick);
    }

    // x-axis
    const xAxisGroup = createSVG('g', {
      attributes: {
        class: 'x-axis',
      }
    });

    const intervalX = Math.round((maxX - minX) / this.maxTicks.x);
    for (let i = 0; i <= this.maxTicks.x; i++) {
      const value = intervalX * i + minX;
      const coordinate = toX(value, minX, maxX, this.paddedWidth);
      const formatted = this.dateFunction(value);

      const text = createSVG('text', {
        properties: {
          textContent: formatted
        },
        attributes: {
          x: 0,
          y: this.paddedHeight + 20
        }
      });
      const line = createSVG('line', {
        attributes: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: this.paddedHeight
        }
      });

      const tick = createSVG('g', {
        attributes: {
          transform: `translate(${coordinate}, 0)`
        }
      });

      tick.appendChild(text);
      tick.appendChild(line);
      xAxisGroup.appendChild(tick);
    }

    // chart-area
    const chartArea = createSVG('g', {
      attributes: {
        class: 'chart-area'
      }
    });
    let scaled = []
    try {
      scaled = this.data.map(([x, y]) => [
        toX(x, minX, maxX, this.paddedWidth),
        toY(y, yAxis.min, yAxis.max, this.paddedHeight)
      ]);
    } catch (err) {
      console.log(err);
      scaled = []
    }
    // console.log('scaled', scaled);
    const d = scaled.reduce((total, [x, y]) => {
      return total += `L${x},${y}`;
    }, '').replace('L', 'M');

    const path = createSVG('path', {
      attributes: {
        d
      }
    });
    chartArea.appendChild(path);

    // data-points
    const dataPoints = createSVG('g', {
      attributes: {
        class: 'data-points'
      }
    });

    scaled.forEach(([x, y]) => {
      const circle = createSVG('circle', {
        attributes: {
          cx: x,
          cy: y,
          r: 4,
          fill: '#4caf50'
        }
      });
      dataPoints.appendChild(circle);
    });

    const { PADDING_X, PADDING_Y } = Constants;
    // combined
    const chart = createSVG('g', {
      attributes: {
        transform: `translate(${PADDING_X / 2}, ${PADDING_Y / 2})`
      }
    });

    chart.appendChild(xAxisGroup);
    chart.appendChild(yAxisGroup);
    chart.appendChild(chartArea);
    chart.appendChild(dataPoints);
    // root.appendChild(chart);

    this.chart = chart;
    // console.log('Paint Finished');
  }
}

customElements.define('fm-line-chart', LineChartElement);