import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html'
})
export class DateRangeComponent {

  constructor() { }

  
}
