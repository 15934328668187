import { DateFormatter, NumberFormatter } from '../../scripts/format.js';

export const createSVG = (tag, props = {}) => {
  const ns = 'http://www.w3.org/2000/svg';
  const el = document.createElementNS(ns, tag);
  const { attributes, properties } = props;

  const keys = Object.keys(attributes);
  const length = keys.length;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    el.setAttribute(key, attributes[key]);
  }

  Object.assign(el, properties);
  return el;
}

export const createElement = (tag, props = {}) => {
  const el = document.createElement(tag);
  const { attributes, properties } = props;

  const keys = Object.keys(attributes);
  const length = keys.length;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    el.setAttribute(key, attributes[key]);
  }

  Object.assign(el, properties);
  return el;
}

export const toX = (value, min, max, width) => {
  return ((value - min) / (max - min)) * width;
}

export const toY = (value, min, max, height) => {
  if ((max - min) === 0) {
    return 0
  } else {
    return height - ((value - min) / (max - min)) * height;
  }
}

export function formatDate(dt) {
  return DateFormatter.format(dt, 'd. mon.');
}

export const formatNumber = number => {

  const exponent = Math.floor(Math.log10(Math.abs(number)));
  const base = Math.pow(10, exponent);

  if (exponent >= 9) {
    return NumberFormatter.format(number / 1000000000, 2) + ' mia.';
  } else if (exponent >= 6) {
    return NumberFormatter.format(number / 1000000, 2) + ' mio.';
  } else if (exponent >= 3) {
    return NumberFormatter.format(number / 1000, 2) + ' t.';
  } else if (exponent == 2) {
    return NumberFormatter.format(number, 1)
  } else if (exponent == 1) {
    return NumberFormatter.format(number, 2)
  } else {
    return NumberFormatter.format(number, 2)
  }
}

export class Chart {
  static scales(min, max, maxTicks) {
    function niceNumber(range, round) {
      const exponent = Math.floor(Math.log10(range));
      const base = Math.pow(10, exponent);
      const fraction = range / base;
      let niceFraction;

      if (round) {
        if (fraction < 1.5) {
          niceFraction = 1;
        } else if (fraction < 3) {
          niceFraction = 2;
        } else if (fraction < 7) {
          niceFraction = 5;
        } else {
          niceFraction = 10;
        }
      } else {
        if (fraction <= 1) {
          niceFraction = 1;
        } else if (fraction <= 2) {
          niceFraction = 2;
        } else if (fraction <= 5) {
          niceFraction = 5;
        } else {
          niceFraction = 10;
        }
      }
      return niceFraction * base;
    }

    const range = niceNumber(max - min, false);
    let spacing = niceNumber(range / (maxTicks - 1), true);
    if (spacing === 0) {
      spacing = 1;
    }
    let niceMin = min;
    if (spacing != 0) {
      let niceMin = Math.round(min / spacing) * spacing;
      if (niceMin > min) {
        niceMin = niceMin - spacing;
      }
    }
    let niceMax = max;
    let ticks = 1;
    if (spacing != 0) {
      niceMax = Math.ceil(max / spacing) * spacing;
      ticks = (niceMax - niceMin) / spacing;
    }

    //console.log(`Scales min ${min} max ${max} maxTics ${maxTicks}`)
    //console.log(`return min ${niceMin} max ${niceMax} spacing ${spacing} ticks ${ticks}`)
    return {
      min: niceMin,
      max: niceMax,
      spacing,
      ticks
    }
  }
}